import * as React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const UsefulInfoPage = () => (
    <Layout>
        <Seo title={'Useful Information'} description={''} lang={'en-gb'} />
        <div className="threeCol purple cfx">
            <aside id="leftCol">
                <div id="tNav">
                    <ul>
                        <li><a href="/help-with-repayments/dealing-with-difficult-times">Dealing
                            with difficult times</a></li>
                        <li><a href="/help-with-repayments/useful-information" className={'selected'}>Useful
                            information</a></li>
                        <li><a href="/help-with-repayments/budget-planner-intro">Budget
                            Planner</a></li>
                    </ul>
                </div>
            </aside>
            <section id="rightCol">
                <div className="contCol">
                    <div className="contColInr">
                        <div className="bubble purple cfx">
                            <div className="inr">
                                <h1>Useful information</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <p className="leading"></p>
                        <p>
                            <strong>Interest Incurred on Arrears <br /><br /></strong>Whilst your Nemo second charge
                            mortgage account is in arrears, additional interest is incurred. This is because your
                            balance is higher than it should have been at this point of your mortgage had you made your
                            payments in full and on time.<br /><br />Arrears interest is added to the loan balance, and if
                            it is not paid off when the arrears are cleared, the arrears interest will itself continue
                            to incur interest until the end of the loan. This means that there may be an outstanding
                            balance remaining at the end of the mortgage term as a result of this.<br /><br />You can speak to one of our helpful agents on 0800 612 9982 to arrange to pay the outstanding interest incurred on arrears, or ensure that any arrangement that you make is sufficient to pay your mortgage in full, and within the number of months or years you have remaining.<br /><br /><strong>Changing your Contractual Payment Date<br /><br /></strong>If
                                    you move your payment date forward (and therefore delay your payment), you will
                                    accrue interest for each day – the accrued interest will be added to the next
                                    payment you make (or can be spread over the next three months upon request). If the
                                    payment date is moved backwards (and therefore sooner in the month), your monthly
                                    payment will remain unchanged.<br /><br /><strong>Fees and Charges<br /><br /></strong>If
                                        your Nemo mortgage falls into arrears, you may incur additional fees and charges
                                        including:</p>
                        <blockquote style={{marginRight: '0px'}} dir="ltr">
                            <p>
                            </p>
                            <table>
                                <tbody>
                                <tr>
                                    <td><strong>Service Fees&nbsp;</strong></td>
                                    <td>&nbsp;<strong>Cost</strong></td>
                                </tr>
                                <tr>
                                    <td>Returned Direct Debit&nbsp;payment&nbsp;</td>
                                    <td>£20</td>
                                </tr>
                                <tr>
                                    <td>Field Agent visit (please see subsequent section for more
                                        information)&nbsp;</td>
                                    <td>up to £90</td>
                                </tr>
                                <tr>
                                    <td>Default notice (please see subsequent section for more information)</td>
                                    <td>£50</td>
                                </tr>
                                </tbody>
                            </table>
                            <p></p>
                        </blockquote>
                        <p dir="ltr">Should we need to take you to court as a result of your arrears , all solicitors
                            and court fees will also be payable by you. These are added to your mortgage balance.</p>
                        <p dir="ltr">Please note that all fees and charges will be added to your mortgage balance under
                            the terms and conditions of your Nemo Mortgage Agreement. This means that there may be an
                            outstanding balance remaining at the end of the mortgage term as a result of this. You may
                            repay these fees and charges any time. If you require further information with regards to
                            fees and charges, please contact us or for further information please click <a
                                href="/our-fees">here</a>.</p>
                        <p dir="ltr">
                            <strong>Credit File </strong>
                        </p>
                        <p dir="ltr">Please note that missed and late Nemo mortgage payments can adversely affect your
                            credit rating and your ability to obtain credit in the future.</p>
                        <p dir="ltr">This can still apply even if you make small monthly or irregular payments to your
                            account if those payments are not equivalent to your contractual payment.</p>
                        <p dir="ltr">
                            <strong>Default</strong></p>
                        <p dir="ltr">If your account is in arrears, we may register a Default with the credit reference
                            agencies. You will be given notice of our intent to do so, and provided with the opportunity
                            to avoid the Default by clearing your arrears or entering into an acceptable arrangement.
                            The Default will appear on your credit file for six years even if you pay off the debt in
                            full. Any creditor who checks your credit file in this time will see the Default, which
                            could have an adverse effect if you apply for other credit.</p>
                        <p dir="ltr">
                            <strong>Field Representatives</strong></p>
                        <p dir="ltr">It is extremely important that we stay in touch with each other, particularly if
                            your Nemo mortgage account is in arrears.</p>
                        <p style={{ marginRight: '0px' }}>However, if all of our efforts to engage with you have
                            failed, we would consider sending our Field Agents out to visit you at your home address.
                            This is simply with the aim of establishing contact with you. Field Agents are not bailiffs
                            and are not instructed to collect payments on our behalf.<br /></p>
                        <p>&nbsp;</p>
                        <p>
                        </p>
                    </div>
                </div>
                <div className="fbCol"></div>
            </section>
        </div>
    </Layout>
)

export default UsefulInfoPage;